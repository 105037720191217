import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import AsyncComputed from 'vue-async-computed'

import 'expose-loader?$!expose-loader?jQuery!jquery'
import 'bootstrap'    //추후 삭제 예정
import 'bootstrap/dist/css/bootstrap.min.css'
import "./assets/css/app.css";
import "./assets/css/common.css";
import "./assets/css/font.css";
import "./assets/css/normalize.css";
import "./assets/css/animate.css";

import "./assets/js/ui-nav.js";
import "./assets/js/ui-toggle.js";
import "./assets/js/ui-client.js";
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

import modal from '@/plugins/modal'
import table from '@/plugins/table'
import cmonitor from '@/plugins/cmonitor' //220128
import apc from '@/plugins/apc' //220611
import common from '@/plugins/common' //230224

// add component
import AddressInput from "@/components/AddressInput";
/* filters */
import filters from '@/filters/commonFilters';
/* 자동검색 기능 추가 */
import VueAutosuggest from "vue-autosuggest";
//대쉬보드 상단 실시간 상황 롤링
import VueCarousel from '@chenfengyuan/vue-carousel';
import VueSlickCarousel from 'vue-slick-carousel'
import ToggleButton from 'vue-js-toggle-button'

//ikkim FCM ==============================
//import firebase from "firebase/app";
import './registerServiceWorker'
import "@/services/messaging";
//ikkim FCM ==============================
import VueInputAutowidth from 'vue-input-autowidth'

//211104
//backbutton process: 만일 modal이 떠 있으면 모달을 먼저 지운다.
window.onpopstate = function() {
  //alert('browser-back');
  //console.log("vm======"+Vue.prototype.$modal.vm);
  if(Vue.prototype.$modal.vm != null) {
    Vue.prototype.$modal.backclose = true;
    Vue.prototype.$modal.vm.close();
  }
};

Vue.use(modal);
Vue.use(table);
Vue.use(cmonitor); //220128
Vue.use(apc); //220611
Vue.use(common); //230224
Vue.use(PerfectScrollbar);
Vue.use(VueAutosuggest);
Vue.use(VueSlickCarousel);
Vue.use(AsyncComputed);
Vue.use(ToggleButton);
Vue.use(VueInputAutowidth);


Vue.config.productionTip = false



Vue.component("AddressInput", AddressInput);
Object.keys(filters).forEach((filter) => {
  Vue.filter(filter, filters[filter]);
})

//브라우저 타이틀을 URL을 파싱하여 설정한다.
document.title = Vue.prototype.$common.getUrl().title;
//alert(document.title);
//console.dir(document);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


